import React from 'react';
import { Link } from 'react-router-dom';

export default ({
  light,
  imgStyle,
  imageClass,
  containerStyle,
  ...linkProps
}) => {
  return (
    <Link to="/" {...linkProps}>
      <span style={containerStyle}>
        {light ? (
          <img
            className={imageClass || 'light-logo'}
            src="/images/logos/quuly-signature-white.svg"
            alt="Quuly logo"
            style={imgStyle}
          />
        ) : (
          <img
            className={imageClass || 'dark-logo'}
            src="/images/logos/quuly-signature-blue.svg"
            alt="Quuly logo"
            style={imgStyle}
          />
        )}
      </span>
    </Link>
  );
};
