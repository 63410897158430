import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  const lastLocation = useRef(history?.pathname || null);
  useEffect(() => {
    const unlisten = history.listen(a => {
      if (a.pathname !== lastLocation.current) {
        window.scrollTo(0, 0);
      }
      lastLocation.current = a.pathname;
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
