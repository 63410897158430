import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <>
      <div className="columns is-vcentered">
        {/* Content */}
        <div className="column is-5 ">
          <div className="content padding-20">
            <h2 className="title is-3 clean-text light-text no-margin-bottom">
              Host office hours anywhere
            </h2>
            <p className="light-text no-margin-bottom pt-10 pb-10">
              With Quuly, teaching assistants and tutors can manage one on one
              remote tutoring sessions. Scheduling and future help sessions are
              coming soon.
            </p>
            <div style={{display: 'none'}} className="pt-10 pb-10">
              <Link
                to="/signup"
                className="button button-cta light-btn btn-outlined is-bold"
              >
                Try now
              </Link>
            </div>
          </div>
        </div>
        {/* Featured image */}
        <div className="column is-6 is-offset-1">
          <img
            className="clean-hero-mockup"
            src="/images/work-laptop.jpg"
            alt="Laptop"
            style={{
              borderRadius: '6px',
              boxShadow:
                '0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)'
            }}
          />
        </div>
      </div>
    </>
  );
};
