import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
export default () => {
  return (
    <>
      {/* Hero and nav */}
      <div className="hero is-theme-primary">
        <Navbar />
        <div
          id="main-hero"
          className="hero-body is-clean"
          style={{ marginTop: '7.5em' }}
        >
          <div className="container has-text-centered">
            <div className="columns is-vcentered">
              <div className="column has-text-centered">
                <h1 className="clean-title light-text">Terms</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ margin: '2.2em' }}>
        <h3 className="subtitle">1. Terms of Use</h3>
        <p>
          By accessing this website (quuly.com), you are agreeing to be bound by
          these Terms of Use, all applicable laws and regulations, and agree
          that you are responsible for compliance with any applicable local
          laws. If you do not agree with any of these terms, you are prohibited
          from using or accessing this site. The materials contained in this
          website are protected by applicable copyright and trademark law.
        </p>
        <br />
        <h3 className="subtitle">2. Usage</h3>
        <p>
          Subject to the terms and conditions of these Terms of Use, you are
          hereby granted a non-exclusive, limited, non-transferable, freely
          revocable license to use the Service for your personal, noncommercial
          use only and as permitted by the features of the Service. Quuly, LLC
          reserves all rights not expressly granted herein in the Service.
          Quuly, LLC may terminate this license at any time for any reason or no
          reason.
        </p>
        <br />
        <h3 className="subtitle">3. Disclaimer</h3>
        <p>
          The materials on Quuly, LLC’s website and Quuly, LLC’s services are
          provided on an ‘as is’ basis. Quuly, LLC. makes no warranties,
          expressed or implied, and hereby disclaims and negates all other
          warranties including, without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property or other violation of
          rights.
        </p>
        <p>
          Further, Quuly, LLC does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of
          the materials on its website or otherwise relating to such materials
          or on any sites linked to this site.
        </p>
        <br />
        <h3 className="subtitle">4. Limitations</h3>
        <p>
          In no event shall Quuly, LLC or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on Quuly, LLC’s website, even if Quuly,
          LLC or a Quuly, LLC authorized representative has been notified orally
          or in writing of the possibility of such damage. Because some
          jurisdictions do not allow limitations on implied warranties, or
          limitations of liability for consequential or incidental damages,
          these limitations may not apply to you.
        </p>
        <br />
        <h3 className="subtitle">5. Accuracy of materials</h3>
        <p>
          The materials appearing on Quuly, LLC’s website could include
          technical, typographical, or photographic errors. Quuly, LLC. does not
          warrant that any of the materials on its website are accurate,
          complete or current. Quuly, LLC may make changes to the materials
          contained on its website at any time without notice. However Quuly,
          LLC does not make any commitment to update the materials.
        </p>
        <br />
        <h3 className="subtitle">6. Links</h3>
        <p>
          Quuly, LLC has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by Quuly, LLC of the
          site. Use of any such linked website is at the user’s own risk.
        </p>
        <br />
        <h3 className="subtitle">7. Modifications</h3>
        <p>
          Quuly, LLC may revise these terms of service for its website at any
          time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service.
        </p>
        <br />
        <h3 className="subtitle">8. Governing Law</h3>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Maryland and you irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location.
        </p>
        <br />
        <h2 className="clean-section-title">Privacy Policy</h2>
        <h3 className="subtitle">Student Data</h3>
        <p>
          <strong>
            Quuly takes the privacy of its users very seriously. Any data
            submitted to our platform belongs to its creator. No user data
            submitted to the Quuly platform will ever be monetized.
          </strong>
        </p>
        <br />
        <p>
          Quuly may store Student Data that is protected as defined in the
          Family Educational Rights and Privacy Act (“FERPA”), 20 U.S.C. §
          1232(g). Quuly will not share this data with anyone except as
          described in this policy.
        </p>
        <br />
        <p>
          Your personal information will never be disclosed to third parties
          without prior written consent except where required by law; to protect
          our own rights; to provide a service to you; or in regards to a
          company transaction, such as a merger, sale of company assets or
          shares, reorganization, financing, change of control or acquisition of
          all or a portion of our business by another company or third party,
          provided that the third party agrees to adhere to privacy standards at
          least as stringent as those named here.
        </p>
        <br />
        <p>
          Quuly will not provide third party access to Student Data through an
          API, external SDKs or libraries, documents, data transfers, database
          dumps or backups, or any other means of software or hardware access
          except as documented in these terms.
        </p>
        <br />
        <p>
          <strong>Student data inspection and correction: </strong>
          Students have the ability to access, inspect, and review the
          information Quuly collects by contacting{' '}
          <a href="mailto:support@quuly.com">Support</a>. Students may challenge
          information we collect and correct its contents by contacting{' '}
          <a href="mailto:support@quuly.com">Support</a>.
        </p>
        <br />
        <p>
          We may disclose anonymized or aggregated versions of such data for
          business, marketing, or public relations purposes, or as part of
          providing our service. Additionally, users may voluntarily submit
          content to public components of our platform. We do not control how
          much personal information a user may voluntarily share through such
          features.
        </p>
        <br />
        <p>
          <strong>Data retention </strong>
          We will retain your personal information for the period necessary to
          fulfill the purposes described in this Privacy Policy unless a longer
          data retention period is required or permitted by law (including for
          as long as needed to provide you with products and services).
        </p>
        <br />
        <h3 className="subtitle">Cookies</h3>
        <p>
          We use “cookies” to collect information about you and your activity
          across our site. A cookie is a small piece of data that our website
          stores on your computer, and accesses each time you visit, so we can
          understand how you use our site. This helps us serve you content based
          on preferences you have specified.
        </p>
        <p>
          We may use third party web site analytic tools such as Google
          Analytics on our website that employ cookies to collect certain
          information concerning your use of our services.
        </p>
        <p>
          If you do not wish to accept cookies from us, you can instruct your
          browser to refuse cookies from our website. If you instruct your
          browser to refuse cookies from our website we may be unable to provide
          you with some of our services. Most browsers are configured to accept
          cookies by default, but you can update these settings to either refuse
          cookies altogether, or to notify you when a website is trying to set
          or update a cookie. This policy covers only the use of cookies between
          your computer and our website and does not cover the use of cookies by
          any advertisers.
        </p>
        <br />
        <h3 className="subtitle">Security</h3>
        <p>
          We take security seriously, and do what we can within commercially
          acceptable means to protect your personal information from loss or
          theft, as well as unauthorized access, disclosure, copying, use or
          modification. However, no method of transmission over the internet, or
          method of electronic storage, is 100% secure however. Therefore we
          cannot guarantee its absolute security.
        </p>
        <br />
        <h3 className="subtitle">Children’s Privacy</h3>
        <p>
          Quuly, LLC does not intend its services to be used by children under
          the age of 13 and we do not knowingly collect or store personal
          information from children (visitors under the age of 13). If you are
          under the age of 13, you are ineligible to use of our services. If you
          believe your child has provided us with personal information please
          contact us immediately and we will delete the data as quickly as we
          can.
        </p>
        <br />
        <h3 className="subtitle">Privacy Policy Changes</h3>
        <p>
          Quuly, LLC may change its privacy policy from time to time. Quuly, LLC
          encourages visitors to frequently check this page for any changes to
          its Privacy Policy. If we make changes to our privacy policy, we will
          notify registered users of such updates. Your further use of the site
          or services provided by Quuly, LLC after a change to our privacy
          policy will be subject to the updated policy.
        </p>
        <br />
        <h3 className="subtitle">Business Transfers</h3>
        <p>
          In connection with any merger, sale of company assets, or acquisition
          of all or a portion of our business by another company, or in the
          unlikely event that Quuly, LLC goes out of business or enters
          bankruptcy, user information would likely be one of the assets that is
          transferred or acquired by a third party. If any of these events were
          to happen, this Privacy Policy would continue to apply to your
          information and the party receiving your information may continue to
          use your information, but only consistent with this Privacy Policy.
        </p>
      </div>
      <Footer />
    </>
  );
};
