import React from 'react';
import { Link } from 'react-router-dom';

export default ({ type, children, ...props }) => {
  return (
    <Link
      className="button button-signup btn-align is-bold light-btn"
      to={type === 'signup' ? '/signup' : `?display=${type || 'waitlist'}`}
      replace={false}
      {...props}
    >
      {children ? children : 'Get started for free'}
    </Link>
  );
};
