import React, { useState, useEffect } from 'react';
import {
  faEnvelope,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCountUp } from 'react-countup';
import ReCAPTCHA from 'react-google-recaptcha';

import Navbar from './Navbar';
import Footer from './Footer';
import IntegrateYourProcess from './Storefront/HowQuulyWorks';
import HowItImproves from './Storefront/HowItImproves';
import VideoOffering from './Storefront/VideoOffering';
import AboutUs from './Storefront/AboutUs';

import CTA from './CTA';
import ValueProps from './Storefront/ValueProps';

import './Storefront/Storefront.css';
import { doSignup } from './SignupUtils';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import BottomBar from './BottomBar';

const NUM_QUESTIONS_ASKED = 45000;
const NUM_INTERACTIONS_MEASURED = 100000;
const NUM_COURSE_STAFF = 5000;

const Stats = () => {
  const { countUp: questionsAsked } = useCountUp({
    end: NUM_QUESTIONS_ASKED,
    start: NUM_QUESTIONS_ASKED / 2,
    duration: 1,
    useEasting: true,
    formattingFn: (value) =>
      value === NUM_QUESTIONS_ASKED ? `${value}+` : value,
  });
  const { countUp: interactionsMeasured } = useCountUp({
    end: NUM_INTERACTIONS_MEASURED,
    start: NUM_INTERACTIONS_MEASURED / 2,
    duration: 1.25,
    useEasting: true,
    formattingFn: (value) =>
      value === NUM_INTERACTIONS_MEASURED ? `${value}+` : value,
  });
  const { countUp: courseStaff } = useCountUp({
    end: NUM_COURSE_STAFF,
    start: NUM_COURSE_STAFF / 2,
    duration: 0.9,
    useEasting: true,
    formattingFn: (value) => (value === NUM_COURSE_STAFF ? `${value}+` : value),
  });

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '0em',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <div
        className="box	has-text-centered"
        style={{
          margin: '0.5em',
          maxWidth: '190px',
        }}
      >
        <div className="is-size-2">{questionsAsked} </div>Office hours sessions
      </div>
      <div
        className="box has-text-centered is-primary"
        style={{
          margin: '0.5em',
          maxWidth: '190px',
        }}
      >
        <div className="is-size-2">{interactionsMeasured} </div> Interactions
        measured
      </div>
      <div
        className="box has-text-centered"
        style={{
          margin: '0.5em',
          maxWidth: '190px',
        }}
      >
        <div className="is-size-2">{courseStaff} </div> Course staff & students
      </div>
      <div
        className="box	has-text-centered"
        style={{
          margin: '0.5em',
          maxWidth: '190px',
        }}
      >
        <div className="is-size-2">78%</div> of TAs believe Quuly makes office
        hours <b>less frustrating</b>
      </div>
    </div>
  );
};

const HeroSignup = () => {
  const [email, setEmail] = useState('');
  const [showEmailError, setEmailError] = useState(false);
  const history = useHistory();

  const recaptchaRef = React.createRef();

  useEffect(() => {
    if (email !== '') {
      setEmailError(false);
    }
  }, [email]);

  return (
    <div className="field">
      <form
        style={{display: 'none'}}
        onSubmit={(e) => {
          e.preventDefault();
          //history.replace(`/signup?email=${email}`);
        }}
      >
        <div className="control has-icons-left signup-container">
          <input
            className="input is-medium is-accent-focus signup-form"
            type="email"
            placeholder="Your .edu email..."
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onSubmit={() => {
              recaptchaRef.current
                .execute()
                .then((val) => doSignup(email, val));
            }}
            value={email}
          />
          <span className="icon is-left signup-form-icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          <button
            type="submit"
            disabled={email === ''}
            className="button button-signup btn-align is-bold secondary-btn hero-signup-cta"
          >
            Get Started
          </button>
        </div>
      </form>
      {/*
      <span style={{ color: 'white', fontSize: '0.9em' }}>
        By registering, you agree to Quuly's{' '}
        <Link
          to="/terms-and-privacy"
          style={{ color: 'white', textDecoration: 'underline' }}
        >
          Terms Of Use
        </Link>{' '}
        and{' '}
        <Link
          to="/terms-and-privacy"
          style={{ color: 'white', textDecoration: 'underline' }}
        >
          Privacy Policy
        </Link>
      </span>
        */}

      {showEmailError && (
        <span style={{ color: 'white', fontSize: '0.9em' }}>
          <FontAwesomeIcon icon={faExclamationTriangle} /> &nbsp;Please enter
          your school email
        </span>
      )}
      <div className="control has-icons-left signup-container recaptcha">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          size="invisible"
          ref={recaptchaRef}
        />
      </div>
    </div>
  );
};

const Hero = () => (
  <div className="hero is-theme-primary">
    <div className="navbar-placeholder" style={{ height: '80px' }}></div>
    <Navbar />
    {/* Hero image */}
    <div id="main-hero" className="hero-body is-clean">
      <div
        className="container has-text-centered"
        style={{ minHeight: '554px' }}
      >
        <div className="columns is-vcentered">
          <div className="column is-5 caption-column has-text-left">
            <h1 className="clean-title light-text">
              Remote office hours scheduling
            </h1>
            <div className="subtitle is-5 no-margin">
              Quuly is a feature-rich{' '}
              <strong style={{ color: 'white' }}>
                ticket-based appointment system
              </strong>{' '}
              that lets students seek remote help quickly. Save students and TAs
              time.
            </div>
            <div className="cta-wrapper has-text-left">
              <HeroSignup />
            </div>
          </div>

          <div className="column is-7">
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              width="600px"
              height="348px"
              className="video-hero-container"
            >
              <source src="/home.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Farewell = () => {
  return (
    <div id="team-message">
      <h2 className="clean-section-title">Important update</h2>
      <h3 className="subtitle is-5 pt-10 pb-10">
        Dear Quuly Customers,
        <br />
        <br />
        After nearly three years and tens of thousands of office hours sessions
        hosted, we regret to announce that we have shut down the Quuly Cloud
        platform. This was a difficult decision to make and one we did not take
        lightly. Rather than explore a sale or liquidation, we wanted to hold to
        our core values of empowering students and educators to do their best
        work. To let Quuly live on, we are pleased to announce that we are open
        sourcing Quuly Cloud to the community later this year while we work with
        existing customers to ensure continuity of service.
        <br />
        <br />
        Some logistics:{' '}
        <b>The Quuly Cloud software is suspended as of August 31st, 2022.</b>{' '}
        All Quuly Cloud data will be deleted by October 31st, 2022.
        We are working with existing customers to operationalize instances of the Quuly cloud software. More information on instances at individual institutions is available below this message.
        <br />
        <br />
        Thank you for using our service in these ever-changing times. If you
        have any questions about these changes, please email support@quuly.com.
        <br />
        <br />
        Sincerely,
        <br />
        <br />
        The Quuly Team
        <br />
        <br />
      </h3>
      <h3 className="clean-section-title">Institutional login</h3>
      <h3>
        <b>University of Maryland, Computer Science:</b> if you are affiliated with the UMD CS department, you may log in to the <a href="https://officehours.cs.umd.edu/">Quuly instance hosted instance here.</a>
        <br />
        <br />
      </h3>
    </div>
  );
};

const UsedBy = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        className="box has-text-centered"
        style={{
          margin: '0.5em',
          maxWidth: '400px',
        }}
      >
        Used by courses at the
        <img
          alt="University of Maryland Logo"
          src="/images/logos/UMD_primary_mark.jpg"
          style={{ marginTop: '1.25em' }}
        />
      </div>
    </div>
  );
};

export default () => {
  return (
    <>
      <section>
        <Hero />
        <section
          id="product"
          className="section section-light-grey is-skewed-sm is-medium"
          style={{ paddingTop: '50px' }}
        >
          <div className="container is-reverse-skewed-sm">
            <Farewell />
            <UsedBy />
            <Stats />
            <ValueProps />
          </div>
        </section>
        <section
          id="how-it-works"
          className="section section-feature-grey is-medium is-skewed-sm"
        >
          <div className="container is-reverse-skewed-sm">
            <IntegrateYourProcess />
          </div>
        </section>
        <section id="features" className="section is-medium is-skewed-sm">
          <div className="container is-reverse-skewed-sm">
            <HowItImproves />
          </div>
        </section>
        <section className="section section-primary is-medium is-skewed-sm is-relative">
          <div className="container slanted-container is-reverse-skewed-sm pt-40 pb-40">
            <VideoOffering />
          </div>
        </section>
        <section
          id="about-us"
          className="section section-feature-grey is-medium is-skewed-sm"
        >
          <div className="container is-reverse-skewed-sm">
            <AboutUs />
          </div>
        </section>

        {/*
      <section id='business-types' className='section is-medium is-skewed-sm'>
        <div className='container is-reverse-skewed-sm'>
          <Clients />
        </div>
      </section>
      */}
        {/*
      <section
        id='card-testimonials'
        className='section section-feature-grey is-medium is-skewed-sm'
      >
        <div className='container is-reverse-skewed-sm'>
          <Testimonials />
        </div>
      </section>
      */}
        {/* CTA */}
        <section id="cta" style={{display: 'none'}} className="section is-medium is-skewed-sm">
          <div className="container is-reverse-skewed-sm">
            <div className="section-title-wrapper">
              <div className="clean-bg-symbol">
                <i className="fa fa-gg" />
              </div>
              <div>
                <h2 className="clean-section-title has-text-centered">
                  Dive in to Quuly for free.
                </h2>
              </div>
            </div>
            <div className="content">
              <h4 className="has-text-centered">
                Join 5000 TAs, students, and instructors already that have
                already used Quuly to organize their course.
                <br />
                We are excited to open source Quuly in early 2023
              </h4>
            </div>
            <div className="has-text-centered is-title-reveal pt-40 pb-40">
              <CTA
                className={'button button-cta btn-align raised secondary-btn'}
                type="signup"
              >
                Try it free
              </CTA>
            </div>
          </div>
        </section>
        <section>
          <div
            style={{
              padding: '2em',
              fontSize: '0.85em',
              width: '100%',
              textAlign: 'center',
            }}
          >
            Any institution listing on this page is solely for informational
            purposes and does not constitute an endorsement of Quuly or its
            products. Please{' '}
            <span
              onClick={() => {
                window.location = 'mailto:team@quuly.com';
              }}
            >
              contact us
            </span>{' '}
            if your institution would like to update its informational listing
            on this page.
          </div>
        </section>
        {/* /CTA */}
        <Footer />
      </section>
      <BottomBar />
    </>
  );
};
