import React from 'react';
import CTA from '../CTA';
import './HowQuulyWorks.css';

export default () => {
  return (
    <>
      <div className="section-title-wrapper has-text-centered">
        <div className="clean-bg-symbol">
          <i className="fa fa-gg" />
        </div>
        <div>
          <h2 className="clean-section-title">How Quuly Works</h2>
          <h3 className="subtitle is-5 pt-10 pb-10">
            Quuly lets teaching assistants and peer students focus on helping
            students succeed.
          </h3>
        </div>
      </div>
      <div className="content-wrapper">
        {/* Hover boxes */}
        <div className="columns is-vcentered">
          <div className="column" />
          <div className="column is-10">
            <div>
              <div className="flex-card icon-card-hover first-card light-bordered padding-20 step-box">
                <h3 className="card-title is-clean">
                  1. &nbsp;A student needs help
                </h3>
                <p className="card-description">
                  Students log in to Quuly, select a room, and submit their
                  question to available teaching assistants.
                </p>
              </div>
              <div className="flex-card icon-card-hover third-card light-bordered padding-20 step-box">
                <h3 className="card-title is-clean">
                  2. &nbsp;TA notifies student
                </h3>
                <p className="card-description">
                  The student is alerted when a teaching assistant or peer tutor
                  is ready to assist them with optional{' '}
                  <b>video conferencing</b>
                </p>
              </div>
              <div className="flex-card icon-card-hover second-card light-bordered padding-20 step-box">
                <h3 className="card-title is-clean">3.&nbsp; Feedback</h3>
                <p className="card-description">
                  After the interaction is complete, TAs may provide private
                  feedback and add a public FAQ items. Students may re-submit
                  follow up questions
                </p>
              </div>
              <div className="flex-card icon-card-hover fourth-card light-bordered padding-20 step-box">
                <h3 className="card-title is-clean">4. &nbsp; Analytics</h3>
                <p className="card-description">
                  Instructors and other educators can use Quuly's historical
                  question data to identify frequent visitors and unearth useful
                  statistics.
                </p>
              </div>
            </div>
          </div>
          <div className="column" />
        </div>
        <div className="has-text-centered is-title-reveal pt-40 pb-40">
          <CTA
            style={{display: 'none'}}
            type="signup"
            className="button button-cta btn-align raised secondary-btn"
          >
            Get started
          </CTA>
        </div>
      </div>
    </>
  );
};
