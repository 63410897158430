import React, { useEffect } from 'react';

export default ({ ...props }) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  });
  return (
    <a
      href="#"
      onClick={() => {
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/quuly/demo'
        });
        return false;
      }}
      {...props}
    >
      Get a demo
    </a>
  );
};
