import React from 'react';

const Testimonial1 = () => {
  return (
    <div
      className="flex-card testimonial-card light-raised padding-20"
      style={{ marginTop: '25px' }}
    >
      <div className="testimonial-text">
        Quuly provides great office hours support regardless of class size.
        Great tool that is easy to use!
      </div>
      <div className="user-id">
        <div className="info">
          <div className="name clean-text">Nelson Padua-Perez</div>
          <div className="position">
            Computer Science Instructor, University of Maryland
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  return (
    <>
      <div className="section-title-wrapper has-text-centered">
        <div>
          <h2 className="clean-section-title">What is Quuly?</h2>
          {/*<h3 className="subtitle is-5 pt-10 pb-10">
            TODO: consider Quuly is a feature-rich ticket-based appointment
            system that makes busy office hours organized, efficient,
            measurable, and painless.
          </h3>*/}
        </div>
      </div>

      <div className="content-wrapper">
        <div className="columns is-vcentered">
          <div className="column is-5 is-offset-1 has-text-centered">
            <div className="columns is-vcentered has-text-centered is-multiline">
              <div className="column is-6">
                <div className="flex-card icon-card light-bordered hover-inset padding-20">
                  <img src="/images/illustrations/plane.svg" alt="" />
                  <div className="icon-card-text is-clean mt-10">Queueing</div>
                </div>
              </div>
              <div className="column is-6">
                <div className="flex-card icon-card light-bordered hover-inset padding-20">
                  <img src="/images/illustrations/tablet-dots.svg" alt="" />
                  <div className="icon-card-text is-clean mt-10">Analytics</div>
                </div>
              </div>
              <div className="column is-6">
                <div className="flex-card icon-card light-bordered hover-inset padding-20">
                  <img
                    src="/images/illustrations/dashboard.svg"
                    alt="Q&A History"
                  />
                  <div className="icon-card-text is-clean mt-10">
                    Q&A History
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <div className="flex-card icon-card light-bordered hover-inset padding-20">
                  <img
                    src="/images/illustrations/chrono.svg"
                    alt="Scheduling"
                  />
                  <div className="icon-card-text is-clean mt-10">
                    Scheduling
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-5 is-offset-1">
            <div className="content padding-20">
              <p
                className="no-margin-bottom pt-5 pb-5"
                style={{ fontSize: '1.25em' }}
              >
                Quuly is an advanced{' '}
                <strong>appointment & queueing system </strong>
                that makes it easy for students to schedule help sessions with
                course staff, tutors, and advisors <b>remotely</b>.
              </p>
              <p className="no-margin-bottom pt-5 pb-5">
                Quuly lets teaching assistants and peer tutors respond to
                student issues <strong>faster</strong> and more
                <strong> efficiently</strong> while promoting{' '}
                <strong>fairness</strong> and student{' '}
                <strong>independence</strong>.
              </p>
              <p className="no-margin-bottom pt-5 pb-5">
                <b>Scheduling:</b> students can schedule future help sessions
                with TAs remotely at a later (coming soon).
              </p>

              <p className="no-margin-bottom pt-5 pb-5">
                TAs and instructors can easily <strong>track</strong> student
                questions{' '}
                {/*and <strong>visit rates</strong> for office hours.
                Quuly lets you*/}{' '}
                and identify frequent visitors.
              </p>

              <div className="pt-10 pb-10">
                <a
                  href="#how-it-works"
                  className="button button-cta btn-align raised primary-btn"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-6 is-offset-3">
            <Testimonial1 />
          </div>
        </div>
      </div>
    </>
  );
};
