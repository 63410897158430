import * as React from 'react';

export default () => {
  return (
    <>
      {' '}
      <div
        style={{
          minHeight: '80px',
          backgroundColor: 'white',
          padding: '15px 80px 15px 20px',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          bottom: '0',
          position: 'fixed',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div>
          <b style={{ color: 'red' }}>Important Update:</b> The Quuly Cloud service is suspended as of <b>August 31st, 2022</b> &nbsp;
          <a href="https://quuly.com/#team-message">
            Read the full update here
          </a>
          .
        </div>
      </div>
    </>
  );
};
