import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import Logo from './Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import CalendlyOpener from './CalendlyOpener';

export default () => {
  return (
    <>
      {/* Side footer */}
      <footer className="footer footer-dark-left">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <div className="mb-20">
                <Logo light imageClass="small-footer-logo" />
              </div>
              <div>
                <span className="moto">Copyright © Quuly, LLC. 2022</span>
                <nav className="level is-mobile mt-20">
                  <div className="level-left level-social">
                    <a
                      href="https://twitter.com/quulyapp"
                      className="level-item"
                    >
                      <span style={{ color: 'white' }}>
                        <FontAwesomeIcon icon={faTwitter} />
                      </span>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/64271093"
                      className="level-item"
                    >
                      <span style={{ color: 'white' }}>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </span>
                    </a>
                  </div>
                </nav>
              </div>
            </div>
            <div className="column">
              <div className="footer-nav-right" style={{ flexWrap: 'wrap' }}>
                <Link className="footer-nav-link" to="/">
                  Home
                </Link>
                <div style={{display: 'none'}}>
                <CalendlyOpener className="footer-nav-link">
                  Get a demo
                </CalendlyOpener>
                <Link className="footer-nav-link" to="/pricing">
                  Pricing
                </Link>
                <Link className="footer-nav-link" to="/signup">
                  Signup
                </Link>
                <Link className="footer-nav-link" to="/login">
                  Login
                </Link>
                </div>
                <Link className="footer-nav-link" to="/#about-us">
                  About us
                </Link>
                <Link className="footer-nav-link" to="/terms-and-privacy">
                  Terms
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* /Side footer */}
    </>
  );
};
