import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bulma';
import './scss/core_blue.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from 'react-router-dom';

import Storefront from './components/Storefront';
import Pricing from './components/Pricing';
import Signup from './components/Signup';
import Login from './components/Login';
import Submit from './components/Submit';
import Terms from './components/Terms';
import ForgotPassword from './components/ForgotPassword';

import './App.css';
import ScrollToTop from './components/ScrollToTop';
import BottomBar from './components/BottomBar';

const CLOUD_HELP_ROOT = `https://help.quuly.com/Quuly%20Cloud%20Help%20Center%2086e9790c468d4338a372798df7e00aea.html`;
const CLOUD_HELP_ZOOM = `https://help.quuly.com/Quuly%20Cloud%20Help%20Center%2086e9790c468d4338a372798df7e00aea/Connecting%20to%20Zoom%20(Students)%203c9c3c8879184cee9ca33c3d8ab3776b.html`;

const ExternalRedirect = ({ to = `${process.env.REACT_APP_ACADIA_URL}/` }) => {
  useEffect(() => {
    // Do not follow for react snap
    if (navigator.userAgent === 'ReactSnap') {
      return;
    }
    window.location.href = to;
  }, [to]);
  return null;
};

const InternalRedirect = ({ to = `${process.env.REACT_APP_ACADIA_URL}/` }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);
  return null;
};

const AppContent = () => {

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/login">
          <Redirect to="/" />
        </Route>
        <Route path="/forgot-password">
          <Redirect to="/" />
        </Route>
        <Route
          path="/signup"
        >
          <Redirect to="/" />
        </Route>
        <Route path="/support/zoom">
          <ExternalRedirect to={CLOUD_HELP_ZOOM} />
        </Route>
        <Route exact path="/support">
          <ExternalRedirect to={CLOUD_HELP_ROOT} />
        </Route>
        <Route path="/pricing">
          <Redirect to="/" />
        </Route>
        <Route path="/submit">
          <Redirect to="/" />
        </Route>
        <Route path="/terms-and-privacy">
          <Terms />
        </Route>
        {/*
        <Route path={`/features`}>
          <Features />
        </Route>
        */}
        <Route path="/q">
          <Redirect to="/" />
        </Route>
        <Route exact path={`/`}>
          <Storefront />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <BottomBar />
    </>
  );
};
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
