import React, { useState, useEffect, useRef } from 'react';
import useFlipOnScroll from '../useFlipOnScroll';
import { HashLink as Link } from 'react-router-hash-link';

import Logo from './Logo';
import CalendlyOpener from './CalendlyOpener';

const BASE_NAV_STYLES = 'navbar navbar-wrapper';
const INITIAL_NAV_STYLES = [
  BASE_NAV_STYLES,
  'is-transparent',
  'navbar-light',
  'navbar-fade'
];

const BEFORE_NAV_STYLES = ['is-transparent navbar-light'];
const AFTER_NAV_STYLES = ['navbar-faded'];

const BASE_BUTTON_STYPES =
  'button button-signup btn-outlined is-bold btn-align raised';

const BEFORE_SIGNUP_BUTTON_STYLES = ['light-btn'];
const INITIAL_SIGNUP_BUTTON_STYLES = [
  BASE_BUTTON_STYPES,
  ...BEFORE_SIGNUP_BUTTON_STYLES
];

const AFTER_SIGNUP_BUTTON_STYLES = ['primary-btn'];

const MobileNavbar = ({ hidden, signupButtonClasses }) => {
  const navClasses = 'navbar-menu' + (!hidden ? ' is-active' : '');
  return (
    <>
      <div className={navClasses}>
        <div className="navbar-start">
          <Link className="navbar-item is-slide" to="/#about-us">
            About us
          </Link>
          <Link className="navbar-item is-slide" to="/support">
            Support 
          </Link>
        </div>
        <div className="navbar-end">
          <div className="navbar-item" style={{display: 'none'}}>
            <Link
              className={signupButtonClasses.join(' ')}
              to="/signup"
              replace={false}
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const maybeMobileNavStyles = [isMobileNavOpen ? 'is-dark-mobile' : ''];

  const initialNavStyles = [...INITIAL_NAV_STYLES, ...maybeMobileNavStyles];
  const beforeNavStyles = [...BEFORE_NAV_STYLES, ...maybeMobileNavStyles];
  const [navbarClasses, position] = useFlipOnScroll(
    65,
    initialNavStyles,
    BASE_NAV_STYLES,
    beforeNavStyles,
    AFTER_NAV_STYLES
  );

  const [signupButtonClasses] = useFlipOnScroll(
    80,
    INITIAL_SIGNUP_BUTTON_STYLES,
    BASE_BUTTON_STYPES,
    BEFORE_SIGNUP_BUTTON_STYLES,
    AFTER_SIGNUP_BUTTON_STYLES
  );

  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsMobileNavOpen(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <nav ref={node} className={navbarClasses.join(' ')}>
      <div className="container">
        <div className="navbar-brand">
          <Logo light={position.y < 65} className="navbar-item" />
          <div
            className="custom-burger"
            onClick={() => {
              setIsMobileNavOpen(!isMobileNavOpen);
            }}
          >
            <a href="/#" className="responsive-btn">
              <span className="menu-toggle">
                <span className="icon-box-toggle">
                  <span className="rotate">
                    <i className="icon-line-top" />
                    <i className="icon-line-center" />
                    <i className="icon-line-bottom" />
                  </span>
                </span>
              </span>
            </a>
          </div>
          {/* /Responsive toggle */}
        </div>
        <MobileNavbar
          hidden={!isMobileNavOpen}
          signupButtonClasses={signupButtonClasses}
        />
      </div>
    </nav>
  );
};
