import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAward,
  faChalkboardTeacher,
  faUserGraduate,
  faUniversity,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';

const HowItWorks = () => {
  return (
    <div className="pb-10 pt-10">
      <a
        href="#how-it-works"
        className="button btn-align btn-more is-link color-primary"
      >
        Learn more about how it works
        <i className="sl sl-icon-arrow-right" />
      </a>
    </div>
  );
};

export default () => {
  const [selected, setSelected] = useState('ta');
  const instrTabClasses =
    `tab-link ` + (selected === 'instructor' ? 'is-active' : '');
  const taTabClasses = `tab-link ` + (selected === 'ta' ? 'is-active' : '');
  const studentTabClasses =
    `tab-link ` + (selected === 'students' ? 'is-active' : '');

  const instrContentClasses =
    'navtab-content pt-20 pb-20 ' +
    (selected === 'instructor' ? 'is-active' : '');

  const taContentClasses =
    'navtab-content pt-20 pb-20 ' + (selected === 'ta' ? 'is-active' : '');

  const studentsContentClasses =
    'navtab-content pt-20 pb-20 ' +
    (selected === 'students' ? 'is-active' : '');

  return (
    <>
      {/* Title */}
      <div className="section-title-wrapper has-text-centered">
        <div className="clean-bg-symbol">
          <i className="fa fa-gg" />
        </div>
        <div>
          <h2 className="clean-section-title">Reinvent office hours.</h2>
          <h3 className="subtitle is-5 pt-10 pb-10">
            Here's how Quuly boosts your course
          </h3>
        </div>
      </div>
      <div className="content-wrapper">
        {/* Navigation pills */}
        <div className="columns is-vcentered">
          <div className="column">
            <div className="navigation-tabs outlined-pills animated-tabs mb-40">
              <div className="tabs is-centered">
                <ul>
                  <li className={instrTabClasses} data-tab="instructors">
                    <a
                      onClick={() => {
                        setSelected('instructor');
                      }}
                    >
                      Instructors
                    </a>
                  </li>
                  <li className={taTabClasses} data-tab="teaching-assistants">
                    <a
                      onClick={() => {
                        setSelected('ta');
                      }}
                    >
                      Teaching assistants
                    </a>
                  </li>
                  <li className={studentTabClasses} data-tab="students">
                    <a
                      onClick={() => {
                        setSelected('students');
                      }}
                    >
                      Students
                    </a>
                  </li>
                </ul>
              </div>
              {/* Pill content */}
              <div id="instructors" className={instrContentClasses}>
                <div className="columns is-vcentered">
                  <div className="column is-5">
                    {/*
                    <figure className='image'>
                      <img
                        src='assets/images/illustrations/mockups/landing1/opportunities.png'
                        alt=''
                      />
                    </figure>
                    */}
                    <div style={{ width: '100%', height: '180px' }}>
                      <FontAwesomeIcon
                        style={{ width: '100%', height: '100%' }}
                        icon={faChartBar}
                      />
                    </div>
                  </div>
                  <div className="column is-7">
                    <div className="content padding-20">
                      <h2 className="feature-headline is-clean">
                        Understand your students better.
                      </h2>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Quuly empowers instructors by helping them make more
                        informed decisions with Quuly’s auditble interaction
                        data.{' '}
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Unearth new insights about your course. Quuly helps
                        instructors identify students that need more attention
                        and analyze how course staff are helping students.
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Find trends in conversations and determine what course
                        subjects and assignments generate higher office hours
                        visits.
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Discover peak hours in office hour rooms and measure the
                        volume of interactions between course staff and
                        students.
                      </p>
                      <HowItWorks />
                    </div>
                  </div>
                </div>
              </div>
              {/* Pill content */}
              <div id="teaching-assistants" className={taContentClasses}>
                <div className="columns is-vcentered">
                  <div className="column is-5">
                    <div style={{ width: '100%', height: '180px' }}>
                      <FontAwesomeIcon
                        style={{ width: '100%', height: '100%' }}
                        icon={faChalkboardTeacher}
                      />
                    </div>
                  </div>
                  <div className="column is-7">
                    <div className="content padding-20">
                      <h2 className="feature-headline is-clean">
                        Help your students more effectively.
                      </h2>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Reduce crowding during in-person office hours. Quuly
                        helps TAs stay organized and save time while helping
                        students. No more searching for the next student in line
                        for assistants.
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Help students more effectively with a history of the
                        students previous questions.
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Keep office hours fair by ensuring students only get
                        helped when they are next in the queue.
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Add daily question limits to promote student
                        independence.
                      </p>

                      <HowItWorks />
                    </div>
                  </div>
                </div>
              </div>
              {/* Pill content */}
              <div id="students" className={studentsContentClasses}>
                <div className="columns is-vcentered">
                  <div className="column is-5">
                    <div style={{ width: '100%', height: '180px' }}>
                      <FontAwesomeIcon
                        style={{ width: '100%', height: '100%' }}
                        icon={faUserGraduate}
                      />
                    </div>
                  </div>
                  <div className="column is-7">
                    <div className="content padding-20">
                      <h2 className="feature-headline is-clean">
                        Get the help you need faster and spend less time waiting
                        for a TA.
                      </h2>
                      <p className="no-margin-bottom pt-5 pb-5">
                        See the office hours queue size ahead of time.
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Quuly helps you keep track of previous questions asked
                        in office hours.
                      </p>
                      <p className="no-margin-bottom pt-5 pb-5">
                        Never be forgotten — If you’re in the queue, you’ll get
                        help{' '}
                      </p>
                      <HowItWorks />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Navigation pills */}
      </div>
    </>
  );
};
