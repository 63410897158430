import React from 'react';
import CTA from '../CTA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import './AboutUs.css';

export default () => {
  return (
    <>
      <div className="section-title-wrapper has-text-centered">
        <div>
          <h2 className="clean-section-title">Built by teaching assistants</h2>
          <h3 className="subtitle is-5 pt-10">
            Quuly was started by TAs with over <strong>10 </strong> combined
            semesters of instruction experience.
          </h3>
        </div>
      </div>
      <div className="content-wrapper">
        <h2 className="title is-3 cean-text has-text-centered">
          Students deserve better
        </h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ maxWidth: '680px' }}>
            <div className="content">
              We noticed a pattern after helping hundreds of University of
              Maryland students. Student after student struggled with course
              organization, fighting to get face time with course staff.
            </div>
            <div className="content ">
              In office hours, teaching assistants missed students waiting for
              help, had no previous context for students with complex questions,
              and dealt with absurdly long waiting lines. Getting help became a
              chore. After hours of frustration students left with unanswered
              questions. That’s why we started Quuly.
            </div>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="columns is-vcentered">
          <div className="column is-4 built-by-container">
            <div className="built-by-pic-container">
              <div className="built-by-pic">
                <img src="/images/people/greg.jpg" alt="Greg" />
              </div>
            </div>
            <div className="has-text-centered mt-20">
              <h3 className="is-size-5">Greg</h3>
              <div>Co-Founder and CEO</div>
              <div>Former TA at the University of Maryland</div>
            </div>
          </div>
          <div className="column is-4 built-by-container">
            <div className="built-by-pic-container">
              <div className="built-by-pic">
                <img src="/images/people/daniel.jpg" alt="Daniel" />
              </div>
            </div>
            <div className="has-text-centered mt-20">
              <h3 className="is-size-5">Daniel</h3>
              <div>Co-Founder and CTO</div>
              <div>Former head TA at the University of Maryland</div>
            </div>
          </div>
          <div className="column is-4 built-by-container">
            <div className="built-by-pic-container">
              <div className="built-by-pic built-by-edu">
                <FontAwesomeIcon size="10x" icon={faUsers} color="lightgray" />
              </div>
            </div>

            <div className="has-text-centered mt-20">
              <h3 className="is-size-5">2500+ students and educators</h3>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <h2 className="title is-3 cean-text has-text-centered">
            Continuous improvement
          </h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{ maxWidth: '680px' }}>
              <div className="content">
                What started off as a humble proof of concept help queue webapp
                turned into a full suite of tools to organize peer tutoring and
                office hours for teaching assistants and instructors. Our
                mission is to reduce friction in courses and large tutoring
                groups so no student gets left behind.
              </div>
              <div style={{display: 'none'}} className="content ">
                We work closely with students, teaching assistants, and
                instructors to continuously improve Quuly. Try Quuly today to
                learn why thousands of students and educators have trusted us
                with their courses.
              </div>
            </div>
          </div>
        </div>

        <div className="has-text-centered is-title-reveal pt-40 pb-40">
          <CTA
            style={{'display': 'none'}}
            type="signup"
            className="button button-cta btn-align raised secondary-btn"
          >
            Sign up
          </CTA>
        </div>
      </div>
    </>
  );
};
