import { useState, useEffect, useRef } from "react";
import useWindowScrollPosition from "@rehooks/window-scroll-position";

const useFlipOnScroll = (
  val,
  initialStyles,
  baseStyles,
  beforeStyles,
  afterStyles
) => {
  const [navbarClasses, setNavbarClasses] = useState(initialStyles);
  const prevPositionRef = useRef();

  const position = useWindowScrollPosition({
    throttle: 150
  });

  useEffect(() => {
    prevPositionRef.current = position.y;

    if (position.y >= val) {
      setNavbarClasses([baseStyles, ...afterStyles]);
    }
  }, []);

  useEffect(
    () => {
      if (position.y >= val) {
        setNavbarClasses([baseStyles, ...afterStyles]);
      } else {
        setNavbarClasses([baseStyles, ...beforeStyles]);
      }
    },
    beforeStyles,
    afterStyles
  );

  // scroll down
  useEffect(() => {
    const shouldTransition = prevPositionRef.current < val && position.y >= val;
    if (!shouldTransition) return;
    prevPositionRef.current = position.y;
    setNavbarClasses([baseStyles, ...afterStyles]);
  }, [
    position,
    setNavbarClasses,
    val,
    initialStyles,
    baseStyles,
    beforeStyles,
    afterStyles
  ]);

  useEffect(() => {
    const shouldTransition = prevPositionRef.current >= val && position.y < val;
    if (!shouldTransition) return;
    prevPositionRef.current = position.y;

    setNavbarClasses([baseStyles, ...beforeStyles]);
  }, [
    position,
    setNavbarClasses,
    val,
    initialStyles,
    baseStyles,
    beforeStyles,
    afterStyles
  ]);

  return [navbarClasses, position, setNavbarClasses];
};
export default useFlipOnScroll;
