export const doSignup = (email, password, recaptcha, setError) => {
  doFlow(
    email,
    password,
    recaptcha,
    `${process.env.REACT_APP_API_URL}/register`,
    `${process.env.REACT_APP_ACADIA_URL}/verify`,
    setError
  );
};

export const doLogin = (email, password, recaptcha, setError) => {
  doFlow(
    email,
    password,
    recaptcha,
    `${process.env.REACT_APP_API_URL}/emailLogin`,
    `${process.env.REACT_APP_ACADIA_URL}/`,
    setError
  );
};

const doFlow = (
  email,
  password,
  recaptcha,
  regEndpoint,
  verifyEndpoint,
  setError
) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  formData.append('recaptcha', recaptcha);

  fetch(regEndpoint, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: formData
  })
    .then(res => {
      if (res.status == 200) {
        res
          .text()
          .then(
            t =>
              (window.location.href = verifyEndpoint + (t ? `?token=${t}` : ''))
          );
      } else {
        res.text().then(t => setError(t));
      }
    })
    .catch(e => console.error(e));
};

export const doForgotPassword = (email, recaptcha, done) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('recaptcha', recaptcha);

  fetch(`${process.env.REACT_APP_API_URL}/forgotPassword`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: formData
  })
    .then(res => done())
    .catch(e => {
      done();
      console.error(e);
    });
};
